import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";
import Home from "../components/Pages/Home";
// import AboutUs from "../components/Pages/AboutUs";
import Gallery from "../components/Pages/Gallery"; 
// import ContactUs from "../components/Pages/ContactUs";
import NotFoundPage from "../components/Common/NotFoundPage";

class AppRouter extends Component {
  render() {
    return (
    <BrowserRouter>
      <div>
        <Header />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/gallery" component={Gallery} exact />
          <Route component={NotFoundPage} exact />
        </Switch>
        <Footer />
      </div>
    </BrowserRouter>
    );
  };
};

export default AppRouter;