import React from "react";
import { NavHashLink as NavLink } from 'react-router-hash-link';
import { BrowserRouter } from "react-router-dom";


class MainMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobileMenu: false
    }

    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
  }
  toggleMobileMenu() {
    this.setState({
      mobileMenu: !this.state.mobileMenu
    });

    console.log(this.state.mobileMenu);

    let mobileMenuLineOne = document.querySelector('.mobile-menu__inner__line-one');
    let mobileMenuLineTwo = document.querySelector('.mobile-menu__inner__line-two');
    let mobileMenuLineThree = document.querySelector('.mobile-menu__inner__line-three');
    let mobileMenuContent = document.querySelector('.mobile-menu__content');
    let mobileMenu = document.querySelector('.mobile-menu');

    console.log(mobileMenu);

    if(!this.state.mobileMenu) {
      console.log('here1');
      mobileMenuLineOne.classList.add('open');
      mobileMenuLineTwo.classList.add('open');
      mobileMenuLineThree.classList.add('open');
      mobileMenuContent.classList.add('open');
      mobileMenu.classList.add('open');
    } else {
      console.log('here2');
      mobileMenuLineOne.classList.remove('open');
      mobileMenuLineTwo.classList.remove('open');
      mobileMenuLineThree.classList.remove('open');
      mobileMenuContent.classList.remove('open');
      mobileMenu.classList.remove('open');
    }
  }
  render() {
    return (
      <BrowserRouter>
        <div className={ `main-menu ${this.props.elementName}` }>
          <NavLink to="/" className="main-menu__link" exact>Home</NavLink>
          <NavLink to="/#about" className="main-menu__link" exact>About Us</NavLink>
          <NavLink to="/#gallery" className="main-menu__link" exact>Gallery</NavLink>
          <NavLink to="/#contact" className="main-menu__link" exact>Contact Us</NavLink>
        </div>
        { this.props.elementName === 'header-menu' &&
        <div className="mobile-menu">
          <div onClick={this.toggleMobileMenu} className="mobile-menu__inner">
            <div className="mobile-menu__inner__line-one"></div>
            <div className="mobile-menu__inner__line-two"></div>
            <div className="mobile-menu__inner__line-three"></div>
          </div>
          <div className="mobile-menu__content">
            <div className="mobile-menu__logo">
              <img src="/images/logo.png" alt="Do-Good Pressure Washing Logo" className="img-responsive" />
            </div>
            <NavLink to="/" className="mobile-menu__content__link" exact onClick={this.toggleMobileMenu}>Home</NavLink>
            <NavLink to="/#about" className="mobile-menu__content__link" exact onClick={this.toggleMobileMenu}>About Us</NavLink>
            <NavLink to="/#gallery" className="mobile-menu__content__link" exact onClick={this.toggleMobileMenu}>Gallery</NavLink>
            <NavLink to="/#contact" className="mobile-menu__content__link" exact onClick={this.toggleMobileMenu}>Contact Us</NavLink>
          </div>
        </div> }
      </BrowserRouter>
    );
  }
}

export default MainMenu;