import React from "react";
import MainMenu from "./MainMenu";

const Header = () => (
  <header className='header'>
    <div className='header__inner container'>
      <div className='header__logo'>
        <img src='./images/logo.png' alt='Do-Good Pressure Washing Logo' />
      </div>
      <MainMenu elementName='header-menu' />
    </div>
  </header>
);

export default Header;