import React from "react";
import MainMenu from "./MainMenu";

const Footer = () => (
  <footer className="footer">
    <div className="footer__inner container">
      <MainMenu elementName='footer-menu' />
      <div className="footer__copy">
        <p>&copy; DO-GOOD PRESSURE WASHING 2020 </p>
      </div>
    </div>
  </footer>
);

export default Footer;