import React from "react";

const HomeHero = () => (
  <div className="homehero">
    <div className="homehero__hero">
      <img src="./images/home-hero.png" alt="Man pressure washing" />
      <div className="overlay"></div>

      <div className="homehero__hero__welcome">
        <h1>Need a Quote?</h1>
        <p>We pressure wash any surface leaving it looking brand new.</p>
        <p>Contact us for a <span className="free">free</span> quote!</p>
        <div className="req-quote">
          <a href="#contact"><p>Request a Quote</p></a>
        </div>
      </div>
    </div>
  </div>
);

export default HomeHero;