import React from "react";

const AboutUs = () => (
  <div className="about" id="about">
    <div className="container">
      <div className="about__title">
        <h2>What we do</h2>
      </div>

      <div className="about__content">
        <p>Located in sydney and servicing the greater sydney area. We are a team of highly motivated pressure washing specialists. We cater to all kinds of jobs. Residential or commercial, small or large there is no job too big for us.</p>
        <p>We offer amazing transformations, from old and dirty looking to bright and looking brand new! If you are thinking of selling your house, I highly recommend letting us come through and making those exterior photos look a lot better! After all the first thing people see when they walk onto your property is the driveway or walkway to the door.</p>
        <p>We are a friendly team who pride ourself on high quality professional work and customer service. We are extremely clean and caring of surrounding areas.</p>
      </div>
    </div>
  </div>
);

export default AboutUs;