import React from "react";
import HomeHero from "./HomeHero";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import Gallery from "./Gallery";

const Home = () => (
  <div className="home">
    <HomeHero />
    <AboutUs />
    <Gallery />
    <ContactUs />
  </div>
);

export default Home;