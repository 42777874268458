import React, { Component } from "react";

class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      savedInformation: false,
      formInfo: {},
      errorMessage: '',
      formSuccess: '',
      data: null
    }

    this.saveInformation = this.saveInformation.bind(this);
    // this.closeSavedModal = this.closeSavedModal.bind(this);
    this.callBackendAPI = this.callBackendAPI.bind(this);
  }
  isEmpty(value) {
    if(!value) {
      return true;
    } else {
      return false;
    }
  }
  clearInputs() {
    document.querySelector('#quote_form').reset();
  }
  validEmail(email) {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    } else {
      return false;
    }
  }
  isNumber(value) {
    if (/^[0-9]*$/.test(value)) {
      return true;
    } else {
      return false;
    }
  }
  saveInformation(e) {
    e.persist()
    e.preventDefault();

    let error = false;

    const formInfo = {
      firstName: e.target.elements.firstname.value.trim() || '',
      lastName: e.target.elements.lastname.value.trim() || '',
      email: e.target.elements.email.value.trim() || '',
      phone: e.target.elements.phone.value.trim() || '',
      street: e.target.elements.street.value.trim() || '',
      suburb: e.target.elements.suburb.value.trim() || '',
      city: e.target.elements.city.value.trim() || '',
      postcode: e.target.elements.postcode.value.trim() || '',
      job: e.target.elements.job.value.trim() || ''
    }

    //Check for missing description in the description info section
    if(formInfo['job'] === '') {
      this.setState(() => ({ errorMessage: 'Please enter a job description' }));
      error = true;
    }

    //Check for missing inputs in the address info section
    if(formInfo['postcode'] === '' || !this.isNumber(formInfo['postcode']) || formInfo['postcode'].length !== 4) {
      this.setState(() => ({ errorMessage: 'Please enter a valid postcode' }));
      error = true;
    }

    if(formInfo['suburb'] === '') {
      this.setState(() => ({ errorMessage: 'Please enter a suburb' }));
      error = true;
    }

    if(formInfo['city'] === '') {
      this.setState(() => ({ errorMessage: 'Please enter a city' }));
      error = true;
    }

    if(formInfo['street'] === '') {
      this.setState(() => ({ errorMessage: 'Please enter a street' }));
      error = true;
    }

    if(formInfo['email'] === '') {
      this.setState(() => ({ errorMessage: 'Please enter your email address' }));
      error = true;
    }

    if(!this.validEmail(formInfo['email'])) {
      this.setState(() => ({ errorMessage: 'Please enter a valid email address' }));
      error = true;
    }

    if(formInfo['phone'] === '' || !this.isNumber(formInfo['phone']) || formInfo['phone'].length < 8) {
      this.setState(() => ({ errorMessage: 'Please enter a valid phone number' }));
      error = true;
    }

    if(formInfo['lastName'] === '') {
      this.setState(() => ({ errorMessage: 'Please enter your last name' }));
      error = true;
    }

    if(formInfo['firstName'] === '') {
      this.setState(() => ({ errorMessage: 'Please enter your first name' }));
      error = true;
    }

    if(!error) {
      this.setState(() => ({
        savedInformation: true,
        formInfo: formInfo,
      }));
    }

    console.log('this is working');
    return !error;

  }
  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
  callBackendAPI = async (e) => {
    e.preventDefault();

    const validForm = this.saveInformation(e);

    if (!validForm) {
      return;
    }



    // console.log('this is the event', e);

    const data = {
      firstName: document.getElementById('firstname').value,
      lastName: document.getElementById('lastname').value,
      email: document.getElementById('email').value,
      phone: document.getElementById('phone').value,
      street: document.getElementById('street').value,
      suburb: document.getElementById('suburb').value,
      postcode: document.getElementById('postcode').value,
      city: document.getElementById('city').value,
      job: document.getElementById('job').value
    }

    console.log(data);

    try {
      const response = await fetch('https://wjapft9uxd.execute-api.ap-southeast-2.amazonaws.com/prod/sendemail', {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });
      const body = await response.json();
      // console.log('this is the response', body);

      // if (response.status !== 200) {
      //   throw Error(body.message) 
      // }
      // console.log('body', body);
      // console.log('data', data);

      this.setState(() => ({
        formSuccess: body,
      }));

      // return body;
    } catch (error) {
      console.log('this is the error', error);
    }
  };
  render() {
    return(
      <div className="contact" id="contact">
        <div className="container">
          <div className="contact__title">
            <h2>Request a Quote</h2>
          </div>
          {!this.state.formSuccess && <form id="quote_form" className="contact__form" onSubmit={this.callBackendAPI} >
            <div className="contact__form__group">
              <label htmlFor="firstname">First Name</label>
              <input name="firstname" id="firstname" type="text" placeholder="John" />
            </div>
            <div className="contact__form__group">
              <label htmlFor="lastname">Last Name</label>
              <input name="lastname" id="lastname" type="text" placeholder="Smith" />
            </div>
            <div className="contact__form__group">
              <label htmlFor="email">Email</label>
              <input name="email" id="email" type="text" placeholder="example@example.com"/>
            </div>
            <div className="contact__form__group">
              <label htmlFor="phone">Phone Number</label>
              <input name="phone" id="phone" type="text" placeholder="0400 000 000" />
            </div>
            <div className="contact__form__group">
              <label htmlFor="street">Street</label>
              <input name="street" id="street" type="text" placeholder="1 Example Street" />
            </div>
            <div className="contact__form__group">
              <label htmlFor="suburb">Suburb</label>
              <input name="suburb" id="suburb" type="text" placeholder="Suburb/Town" />
            </div>
            <div className="contact__form__group">
              <label htmlFor="postcode">Postcode</label>
              <input name="postcode" id="postcode" type="text" placeholder="2000" />
            </div>
            <div className="contact__form__group">
              <label htmlFor="city">City</label>
              <input name="city" id="city" type="text" placeholder="Sydney" />
            </div>
            <div className="contact__form__group">
              <label htmlFor="job">Job Information</label>
              <textarea id="job" name="job" placeholder="Enter job information here"></textarea>
            </div>
            {this.state.errorMessage && <p className="form_error">{this.state.errorMessage}</p>}
            <div className="contact__form__submit">
              <button type="submit" className="submit">Submit</button>
            </div>
          </form> }
          {this.state.formSuccess && 
            <div className="contact__form__group">
              <p className="success_message">{this.state.formSuccess}</p>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default ContactUs;