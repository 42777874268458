import React from "react";

const Gallery = () => (
  <div className="gallery" id="gallery">
    <div className="container">
      <div className="gallery__title">
        <h2>Gallery</h2>
      </div>
      <div className="gallery__grid">
        <div className="gallery__grid__group">
          <div className="gallery-group-inner">
            <img src="./images/gallery_1.jpg" alt="Transformation" className="gallery-img"/>
          </div>
        </div>
        <div className="gallery__grid__group">
          <div className="gallery-group-inner">
            <img src="./images/gallery_2.jpg" alt="Transformation" className="gallery-img"/>
          </div>
        </div>
        <div className="gallery__grid__group">
          <div className="gallery-group-inner">
            <img src="./images/gallery_3.jpg" alt="Transformation" className="gallery-img"/>
          </div>
        </div>
        <div className="gallery__grid__group">
          <div className="gallery-group-inner">
            <img src="./images/gallery_4.jpg" alt="Transformation" className="gallery-img"/>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Gallery;